<template>
  <div>
    <HomePageTop ref="pagetop"></HomePageTop>
    <section>
      <div class="left">
        <div v-for="(item,index) in catalogueList" :key="index"
             :class="catalogueIndex===index?'catalogueContainer checkedCatalogue':'catalogueContainer'"
             :title="$fanyi(item.name)" @click="updateShowComponents(index);catalogueIndex=index">
          <img :src="catalogueIndex===index?item.activeImg:item.img" alt="">
          {{ $fanyi(item.name) }}
        </div>
      </div>
      <component :is="componentsName" class="right"></component>
    </section>
    <Foot></Foot>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot";
import registrationMethod from "@/views/footList/components/userGuide/registrationMethod";
import productSearchMethod from "@/views/footList/components/userGuide/productSearchMethod";
import howToUseCoupons from "@/views/footList/components/userGuide/howToUseCoupons";
import orderMethod from "@/views/footList/components/userGuide/orderMethod";
import proposeDeliveryMethod from "@/views/footList/components/userGuide/proposeDeliveryMethod";
import plugInUsage from '@/views/footList/components/userGuide/plugInUsage'
import purchaseProcess from '@/views/footList/components/userGuide/purchaseProcess'
import howToUseChat from '@/views/footList/components/userGuide/howToUseChat'
import procedureForSubmittingAPaymentReport
  from '@/views/footList/components/userGuide/procedureForSubmittingAPaymentReport.vue'
import accountConnection from '@/views/footList/components/userGuide/accountConnection.vue'
import warehouseDeliveryProcess from '@/views/footList/components/userGuide/warehouseDeliveryProcess.vue'

export default {
  components: {
    HomePageTop,
    Foot,
    registrationMethod,
    productSearchMethod,
    howToUseCoupons,
    orderMethod,
    proposeDeliveryMethod,
    plugInUsage,
    purchaseProcess,
    howToUseChat,
    procedureForSubmittingAPaymentReport,
    accountConnection,
    warehouseDeliveryProcess
  },
  data() {
    return {
      catalogueList: [
        {
          name: '仕入れの流れ',
          componentsName: 'purchaseProcess',
          img: require('../../assets/userGuide/purchaseProcess.png'),
          activeImg: require('../../assets/userGuide/purchaseProcessActive.png')
        }, {
          name: '注册方法',
          componentsName: 'registrationMethod',
          img: require('../../assets/userGuide/registrationMethod.png'),
          activeImg: require('../../assets/userGuide/registrationMethodActive.png')
        },
        {
          name: 'タオバオ、1688アカウント連結、初回ログイン＆パスワード設定',
          componentsName: 'accountConnection',
          img: require('../../assets/userGuide/accountConnection.png'),
          activeImg: require('../../assets/userGuide/accountConnectionActive.png')
        },
        {
          name: '商品查找方法',
          componentsName: 'productSearchMethod',
          img: require('../../assets/userGuide/productSearchMethod.png'),
          activeImg: require('../../assets/userGuide/productSearchMethodActive.png')
        }, {
          name: '下单方法',
          componentsName: 'orderMethod',
          img: require('../../assets/userGuide/orderMethod.png'),
          activeImg: require('../../assets/userGuide/orderMethodActive.png')
        },
        {
          name: '入金報告の提出手順',
          componentsName: 'procedureForSubmittingAPaymentReport',
          img: require('../../assets/userGuide/paymentReport.png'),
          activeImg: require('../../assets/userGuide/paymentReportActive.png')
        },
        {
          name: '提出发货方法',
          componentsName: 'proposeDeliveryMethod',
          img: require('../../assets/userGuide/proposeDeliveryMethod.png'),
          activeImg: require('../../assets/userGuide/proposeDeliveryMethodActive.png')
        },
        {
          name: '日本倉庫納品の流れ',
          componentsName: 'warehouseDeliveryProcess',
          img: require('../../assets/userGuide/warehouseDeliveryProcess.png'),
          activeImg: require('../../assets/userGuide/warehouseDeliveryProcessActive.png')
        },
        {
          name: '优惠券使用方法',
          componentsName: 'howToUseCoupons',
          img: require('../../assets/userGuide/howToUseCoupons.png'),
          activeImg: require('../../assets/userGuide/howToUseCouponsActive.png')
        }, {
          name: '插件使用方法',
          componentsName: 'plugInUsage',
          img: require('../../assets/userGuide/plugInUsage.png'),
          activeImg: require('../../assets/userGuide/plugInUsageActive.png')
        }, {
          name: 'チャットの使用方法',
          componentsName: 'howToUseChat',
          img: require('../../assets/userGuide/chat0.png'),
          activeImg: require('../../assets/userGuide/chat1.png')
        },
        {
          name: 'よくある質問',
          componentsName: '',
          img: require('../../assets/userGuide/issueIcon.png'),
          activeImg: require('../../assets/userGuide/issueIconActive.png')
        }],
      componentsName: 'purchaseProcess',
      catalogueIndex: 0,
    }
  },
  created() {
    if (this.$route.query.componentsName != undefined) {
      for (let i = 0; i < this.catalogueList.length; i++) {
        if (this.catalogueList[i].componentsName == this.$route.query.componentsName) {
          this.catalogueIndex = i;
          this.updateShowComponents(i);
          break;
        }
      }
    }
  },
  methods: {
    //更新展示的组件
    updateShowComponents(index) {
      if (this.catalogueList[index].componentsName === '') {
        window.open('/questionAndAnswer')
      } else {
        this.componentsName = this.catalogueList[index].componentsName;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  width: 1400px;
  margin: 30px auto;
  display: flex;

  .left {
    border-radius: 10px;
    background: #FFFFFF;
    width: 327px;
    padding: 9px 18px;
    box-sizing: border-box;
    margin-right: 26px;
    max-height: 548px;

    .catalogueContainer {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      color: #000000;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      padding-left: 12px;

      img {
        margin-right: 17px;
        max-height: 22px;
        max-width: 22px;
      }

      div {
        color: #FFFFFF;
        font-size: 18px;
      }
    }

    .checkedCatalogue {
      color: #fff;
      background: #B4272B;
    }
  }

  .right {
    width: 1047px;
    border-radius: 10px;
    background: #FFFFFF;
    padding: 32px;
    box-sizing: border-box;
  }
}
</style>
